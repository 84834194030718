import { useEffect, useRef, useCallback } from 'react';

import { usePageVisibility } from './use-page-visibility';

// Polling hook that calls `pollingFn` at the specified `interval` (default is 3 seconds).
// Pauses polling when the page is not visible or when the component unmounts.
const usePolling = (
  pollingFn: () => void,
  interval = 3000,
  immediate = false,
) => {
  const { isPageVisible } = usePageVisibility();
  const timerIdRef = useRef<number | null>(null);

  const startPolling = useCallback(() => {
    if (immediate) {
      pollingFn(); // Call the function immediately if specified
    }
    timerIdRef.current = window.setInterval(pollingFn, interval);
  }, [interval, pollingFn, immediate]);

  const stopPolling = useCallback(() => {
    if (timerIdRef.current !== null) {
      window.clearInterval(timerIdRef.current);
      timerIdRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (isPageVisible) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling(); // Ensure polling stops on component unmount
    };
  }, [isPageVisible, startPolling, stopPolling]);
};

export { usePolling };
