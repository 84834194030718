import { useEffect, useState } from 'react';

const usePageVisibility = (): { isPageVisible: boolean } => {
  const [isPageVisible, setIsPageVisible] = useState<boolean>(() => {
    if (typeof document !== 'undefined') {
      return document.visibilityState === 'visible';
    }
    return true; // Default to visible if `document` is undefined (e.g., during SSR)
  });

  useEffect(() => {
    if (typeof document === 'undefined') {
      return;
    }

    const handleVisibilityChange = () => {
      setIsPageVisible(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [setIsPageVisible]);

  return { isPageVisible };
};

export { usePageVisibility };
